import {finalize} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {MatCard} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';
import {CacheService, Contest} from '@isifid/core';
import {Router} from '@angular/router';

import {FormComponent} from '../form/form.component';
import {StatusComponent} from '../status/status.component';
import {LogoComponent} from '../logo/logo.component';
import {ResultComponent} from '../result/result.component';
import {SpinnerComponent} from '../spinner/spinner.component';
import {UiService} from '../../shared/services/ui.service';

@Component({
    selector: 'app-card',
    imports: [
        MatCard,
        FormComponent,
        StatusComponent,
        MatIcon,
        LogoComponent,
        ResultComponent,
        SpinnerComponent
    ],
    templateUrl: './card.component.html',
    styleUrl: './card.component.scss'
})
export class CardComponent implements OnInit {
    currentStep: 'loading' | 'status' | 'form' | 'result' = 'loading';
    expired: boolean = false;
    success: boolean = false;
    contest: Contest = new Contest();

    constructor(
        private readonly router: Router,
        private readonly uiService: UiService,
        private readonly cacheService: CacheService
    ) {
    }

    ngOnInit() {
        this.cacheService.clearAllCache();
        this.uiService.init()
            .pipe(finalize(() => this.applyCustomCSS()))
            .subscribe({
                next: (res) => {
                    const now = new Date().toISOString();
                    this.contest = {...this.contest, ...res};
                    if (res.startAt > now) this.currentStep = 'status';
                    else if (res.startAt <= now && ((res.endAt && res.endAt >= now) || !res.endAt)) this.currentStep = 'form';
                    else if (res.startAt <= now && res.endAt && res.endAt < now) {
                        this.currentStep = 'status';
                        this.expired = true;
                    }
                },
                error: () => {
                    this.router.navigate(['404']).then();
                }
            });
    }

    showResult(value: boolean): void {
        this.currentStep = 'result';
        this.success = value;
    }

    private applyCustomCSS(): void {
        const style = document.createElement('style');
        if (this.contest.customCss) style.textContent = this.contest.customCss;
        else style.textContent = this.uiService.defaultBackgroundImageCss;
        document.head.appendChild(style);
    }
}
