import {Component} from '@angular/core';
import {MatIcon, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
    selector: 'app-spinner',
    imports: [MatIcon],
    templateUrl: './spinner.component.html',
    styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
    constructor(
        private readonly iconRegistry: MatIconRegistry,
        private readonly sanitizer: DomSanitizer
    ) {
        this.iconRegistry.addSvgIcon(
            'spinnerSvg',
            this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/img/spinner.svg')
        );
    }
}
