import {Component} from '@angular/core';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {map, Observable} from 'rxjs';

import {UiService} from '../../shared/services/ui.service';

@Component({
    selector: 'app-logo',
    imports: [NgOptimizedImage, AsyncPipe],
    templateUrl: './logo.component.html',
    styleUrl: './logo.component.scss'
})
export class LogoComponent {
    public logo$: Observable<string>;

    constructor(
        public uiService: UiService
    ) {
        this.logo$ = this.uiService.configuration$.pipe(map((s) => s['logoUrl']));
    }
}
